import { lazy, Suspense } from "react";
const AmbassdarPage = lazy(() => import("src/pages/ambassdar/redirection"));
const AmbassdarErrorPage = lazy(() => import("src/pages/ambassdar/error"));
const AmbassdarSuccessPage = lazy(() => import("src/pages/ambassdar/success"));

const Loader = () => <div>Loading...</div>;


export const ambassdarRoutes = [
  {
    children: [
      {
        path: "ambassdar",
        element: (
          <Suspense fallback={<Loader />}>
              <AmbassdarPage />
          </Suspense>
        ),
      },
      {
        path: "ambassdar/error",
        element: (
          <Suspense fallback={<Loader />}>
              <AmbassdarErrorPage />
          </Suspense>
        ),
      },
      {
        path: "ambassdar/success",
        element: (
          <Suspense fallback={<Loader />}>
              <AmbassdarSuccessPage />
          </Suspense>
        ),
      },
    ],
  },
];
