import { authRoutes } from "./auth";
import { dashboardRoutes } from "./dashboard";
import { ambassdarRoutes } from "./ambassdar";
import { Navigate } from "react-router-dom";
import { paths } from "src/paths";
export const routes = [
  {
    path: "/",
    element: <Navigate to={paths.dashboard.calendar} replace />,
  },
  ...authRoutes,
  ...dashboardRoutes,
  ...ambassdarRoutes,
];
